import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";

export const query = graphql`
  query VirtualGalleryTemplateQuery($id: String!) {
    exhibition: sanityExhibition(id: { eq: $id }) {
      id
      title
      virtualGallery
      slug {
        current
      }
    }
  }
`;

const VirtualGalleryTemplate = props => {
  const { data, errors } = props;
  const exhibition = data && data.exhibition;
  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {exhibition && <SEO title={`${exhibition.title} — Virtual Gallery` || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {exhibition && <iframe className="virtual-gallery-container" src={exhibition.virtualGallery} width="100%" height="100%" frameborder="0"></iframe>}
    </>
  );
};

export default VirtualGalleryTemplate;
